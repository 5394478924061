export function intercom(APP_ID, app) {
    const ic = window.Intercom;
    window.intercomSettings = {
        app_id: APP_ID,
        api_base: 'https://api-iam.eu.intercom.io'
    }
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update');
    } else {
        const d = document;
        const i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        window.Intercom = i;
        const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + APP_ID;
            s.onload = () => {
                window.Intercom('update');  // demarre intercom quand le script est chargé
            }
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);

        };
        if (document.readyState === 'complete') {
            l();
        } else if (window.attachEvent) {
            window.attachEvent('onload', l);
        } else {
            window.addEventListener('load', l, false);
        }
    }

    app.ports.intercom_update && app.ports.intercom_update.subscribe(() => {
        window.Intercom('update')
    })

    /** https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session */
    app.ports.intercom_logout && app.ports.intercom_logout.subscribe(() => {
        window.Intercom('shutdown')
    })
    
    app.ports.intercom_loggedin && app.ports.intercom_loggedin.subscribe((authInfo) => {
        if (authInfo.company) {
            window.Intercom('update', {
                email: authInfo.login,
                user_id: authInfo.uid,
                name: `${authInfo.firstName} ${authInfo.lastName}`,
                company: {
                    id: authInfo.company.uid,
                    name: authInfo.company.label
                }
            });
        }
    })
}